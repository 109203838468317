@import "../../../../styles/variables.css";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  font-family: var(--font-family);
  color: var(--color-black);
  background: var(--color-gray);
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

#app {
  display: grid;
  height: 100%;
  grid-template-rows: 3rem 1fr 3rem;

  & > main {
    padding: 1rem;
  }
}
