@import "../../../../styles/variables.css";

#app > header {
  height: 3rem;
  background: var(--color-electric-blue);
  color: var(--color-yellow);
  user-select: none;
  display: grid;
  grid-template-columns: 120px 1fr;
  align-content: center;

  &.is-choosing {
    & .apps-icon {
      visibility: hidden;
    }
    & .name {
      justify-content: center;
      margin: 0;
      & h1 {
        margin-left: -120px;
      }
    }
  }
  & h1 {
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
  }
  & .apps-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    & .link {
      padding: 0.3rem;
      text-decoration: none;
      cursor: pointer;
      color: var(--color-yellow);
      border-bottom: 0.2rem solid transparent;

      &:hover {
        border-bottom: 0.2rem solid var(--color-yellow);
      }
    }
  }
  & .name {
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5em;
  }
}

@media screen and (min-width: 960px) {
  #app > header .name {
    justify-content: center;
    margin: unset;
    & h1 {
      margin-left: -120px;
    }
  }
}
