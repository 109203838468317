@import "../../../../styles/variables.css";

#app > main > .app-chooser {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  align-content: start;

  & .card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    user-select: none;
    border: 0.1rem solid var(--color-slate);
    border-radius: 0.5rem;
    height: 15rem;

    &:hover {
      background: var(--color-slate);
      border: 0.1rem solid var(--color-gray);
      box-shadow: 0.25rem 0.5rem 0.75rem 0.25rem var(--color-electric-blue);

      & .name {
        color: var(--color-yellow);
        border-bottom: 0.2rem solid var(--color-gray);
      }
      & .desc {
        color: var(--color-white);
      }
    }
    & .name {
      display: flex;
      justify-content: center;
      padding: 0.75rem;
      width: 100%;
      border-bottom: 0.2rem solid var(--color-slate);
      font-size: 2rem;
    }
    & .desc {
      padding: 0.75rem;
      font-size: 1.25rem;
      text-align: justify;
    }
  }
}

@media screen and (min-width: 960px) {
  #app > main > .app-chooser {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1500px) {
  #app > main > .app-chooser {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1800px) {
  #app > main > .app-chooser {
    grid-template-columns: repeat(4, 1fr);
  }
}
